import React from "react";
import Term from "../components/layout/Term";
import { useIntl } from "gatsby-plugin-intl"

export default () => {
    const intl = useIntl();
    const props = {
        title: intl.formatMessage({id:"services.productStrategy.title"}),
        description: intl.formatMessage({id:"services.productStrategy.meaning"}),
        benefits: intl.formatMessage({id:"services.productStrategy.benefits"}),
    };
    return(<Term {...props} />);
};